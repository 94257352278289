<template>
  <div class="home">
    <Corgi @touchCongi="handleTouch"></Corgi>
    <!-- <h2>今晚又吃土豆？</h2> -->
    <Corgi-love @touchCongi="handleTouch"></Corgi-love>
  </div>
</template>

<script>
import Corgi from '@/components/Corgi'
import CorgiLove from '@/components/CorgiLove'

export default {
  name: 'home',
  components: {
    Corgi,
    CorgiLove,
  },
  data() {
    return {
    };
  },
  methods: {
    handleTouch(payload) {
      console.log(payload)
      switch (payload) {
        case 'left-eye':
          this.goto('breakfast');
          break;
        case 'right-eye':
          this.goto('breakfast');
          break;
        case 'tail':
          this.goto('lunch');
          break;
        case 'nose':
          this.goto('snack');
          break;
        case 'body':
          this.goto('dinner');
          break;
        case 'love':
          this.$router.push('Marryme');
          break;
        case 'bodyOne':
          this.$router.push('Cards');
          break;
        default:
          this.goto('snack');
          break;
      }
    },
    goto(path) {
      this.$router.push({path: `/meals/${path}`})
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import "~@/styles/colors.less";
.home{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #8C5A46;
  height: 100%;
  h2{
    position: relative;
    left: -18%;
    margin-right: 10%;
    margin-top: 45%;
    min-width: 350px;
    font-size: 45px;
    color: antiquewhite;
    // white-space: nowrap;
  }
  .corgi-anime{
    transform: scale(0.75);
    position: relative;
    left: 0;
    top: -5%;
  }
  .Corgi-love{
    right: 10%;
    top: 70%;
  }
}


ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
