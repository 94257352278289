import { render, staticRenderFns } from "./Meals.vue?vue&type=template&id=233c0dd8&scoped=true&"
import script from "./Meals.vue?vue&type=script&lang=js&"
export * from "./Meals.vue?vue&type=script&lang=js&"
import style0 from "./Meals.vue?vue&type=style&index=0&id=233c0dd8&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "233c0dd8",
  null
  
)

export default component.exports