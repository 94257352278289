// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App';
import router from './router';
import moment from 'moment';
import axios from 'axios';

require('normalize.css');
require('@/styles/global/index.less');

moment.locale('zh-cn'); 

Vue.use(ElementUI);

Vue.config.productionTip = false;

window.$config = {};
if (window.location.hostname !== 'localhost') {
  window.$config.HOST = `http://${window.location.hostname}:3000`
} else { 
  window.$config.HOST = 'http://localhost:3000'
}

axios.defaults.baseURL = window.$config.HOST;
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

Vue.prototype.$axios = axios;
/* eslint-disable no-new */
new Vue({
  router,
  template: '<App/>',
  render: h => h(App)
}).$mount('#app')
