<template>
  <div class="menu">
    
  </div>
</template>

<script>

export default {
  name: 'menu',
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
   
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>

.menu{
  
}
</style>
