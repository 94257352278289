import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home';
import Menu from '@/views/Menu';
import Meals from '@/views/Meals';
import Marryme from '@/views/Marryme';
import Cards from '@/views/Cards';


Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/menu',
      name: 'Menu',
      component: Menu,
    },
    {
      path: '/meals/:type',
      name: 'Meals',
      component: Meals
    },
    {
      path: '/Marryme',
      name: 'Marryme',
      component: Marryme
    },
    {
      path: '/Cards',
      name: 'Cards',
      component: Cards
    }
  ],
});
