<template>
  <div class="meals">
    <div class="header">
      <el-button type="primary" icon="el-icon-refresh" round>随机</el-button>
      <h2>{{mealType}}&nbsp;List</h2>
      <el-input v-for="p in Object.keys(inputData)" 
        :placeholder="p" :key="p" v-model="inputData[p]">
      </el-input>
    </div>
    <div class="sub-header">
      <el-button class="small-btns" v-if="['午餐','晚餐'].includes(nameMap[mealType])" :type="dinnerType!==-1?'primary':'danger'" @click="selectDinner(-1)">全部</el-button>
      <el-button class="small-btns" v-if="['午餐','晚餐'].includes(nameMap[mealType])" :type="dinnerType!==0?'primary':'danger'" @click="selectDinner(0)">速食+定食</el-button>
      <el-button  v-if="['午餐','晚餐'].includes(nameMap[mealType])" :type="dinnerType!==1?'primary':'danger'" @click="selectDinner(1)">标准正餐</el-button>
      <el-select  v-if="['午餐','晚餐'].includes(nameMap[mealType])" v-model="selectValue" multiple clearable 
        @change="updateTable" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-badge  v-if="['午餐','晚餐'].includes(nameMap[mealType])" :value="cart.length" class="item">
        <el-button icon="el-icon-goods" @click="showDialog"></el-button>
      </el-badge>
      <el-button class="small-btns" type="primary" icon="el-icon-plus" @click="showDialog2"></el-button>
      <el-button class="small-btns" type="primary" icon="el-icon-edit-outline" @click="editDinner"></el-button>
      <el-button class="small-btns" type="primary" icon="el-icon-delete" @click="deleteDinner"></el-button>
      <el-button v-if="['午餐','晚餐'].includes(nameMap[mealType])" class="choose" @click="cart=[]">清空点菜单</el-button>
      <el-button class="choose" @click="$refs.mealTable.clearSelection()">取消勾选</el-button>
      <el-button v-if="['午餐','晚餐'].includes(nameMap[mealType])" class="choose" @click="choose" type="primary">加入点菜单</el-button>
    </div>
    <el-dialog title="已选菜品" width="80%" :visible.sync="dialogTableVisible">
      <el-table :data="cart" height="400" @selection-change="updateSelection" border stripe @row-click="selectRow">
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <el-table-column prop="name" label="菜名" width="150"></el-table-column>
        <el-table-column prop="note" label="备注"></el-table-column>
        <el-table-column prop="mark" label="评分" width="100"></el-table-column>
      </el-table>
      <div class="score">总分: {{score}}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cart = []">清 空</el-button>
        <el-button @click="deleteRow" type="danger">删除</el-button>
        <el-button type="primary" @click="dialogTableVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :closeOnClickModal="false" :title="!isEdit?'添加菜品':'修改菜品'" width="50%" :visible.sync="dialogTableVisible2">
      <el-form  ref="form" :model="newMealForm" height="400">
        <!-- <el-table-column fixed type="selection" width="55"></el-table-column> -->
        <el-form-item label="菜名">
          <el-input v-model="newMealForm.name"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="newMealForm.note"></el-input>
        </el-form-item>
        <el-form-item label="评分">
          <el-input-number v-model="newMealForm.score" value="number" :min="0" :max="10" :step="0.1"></el-input-number>
        </el-form-item>
        <el-form-item label="口令">
          <el-input type="password" v-model="newMealForm.pwd"></el-input>
        </el-form-item>
        <el-form-item label="标签">
          <el-select v-model="newMealForm.tags" multiple placeholder="请选择标签">
            <el-option
              v-for="item in tableTagFilters"
              :key="item.value"
              :label="item.value"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="initNewMealForm">清 空</el-button>
        <el-button type="primary" @click="submitDinner" :loading="!dialogTableVisible2">确 定</el-button>
      </span>
    </el-dialog>


    <el-table ref="mealTable" class="meal-table" height="850" :data="mealTableData" border stripe highlight-current-row
        align="center" v-loading="loading" @selection-change="updateSelection" @row-click="selectRow">
      <el-table-column fixed type="selection" width="55"></el-table-column>
      <el-table-column prop="name" label="菜名" width="150"></el-table-column>
      <el-table-column prop="note" label="备注"></el-table-column>
      <el-table-column prop="mark" label="评分" sortable width="100"></el-table-column>
      <el-table-column
        prop="tag"
        label="标签"
        width="200"
        :filters="tableTagFilters"
        :filter-method="filterTag"
        filter-placement="bottom-end">
        <template slot-scope="scope">
          <el-tag style="margin: 0 5px 2px 0;" :key="tag" v-for="tag in scope.row.tag" type="default" close-transition>{{tag}}</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-button icon="el-icon-arrow-left" @click="$router.push('/')">返回首页</el-button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'meals',
  components: {
  },
  created() {
    // postman手动初始化
    // this.$axios.get('/dinners').then(dinners => {
    //   console.log("/dinners :", dinners);
    //   this.$axios.get('/tags').then((tags)=>{
    //     this.tableTagFilters = tags.data.data.map(tag => {
    //       return {text: tag.name, value:tag.name}
    //     })
    //     this.mealList = dinners.data.data.map(dinner => {
    //       return {
    //         name: dinner.name,
    //         mark: dinner.score,
    //         note: dinner.note,
    //         tag: dinner.tags,
    //         id: dinner.id
    //       }
    //     })
    //   })
    //   if(dinners.data.data.length === 0) {
    //     let dinnerList = this.mealList.map(meal => {
    //       return {name: meal.name, score: meal.mark, note: meal.note, tags: meal.tag}
    //     })
    //     this.$axios.post('/addDinners', {data: dinnerList}).then((res) => {
    //       console.log(res);
    //     })
    //   } else {
        
    //   }
    // })
  }, 
  mounted(){
    this.initNewMealForm();
    this.mealType = this.$route.params.type;
    // console.log()
    // let i = 1;
    // this.tempData.forEach(el => {
    //   this.mealList.push({ id: 55+i, name: el[0], mark: el[1], note: el[2], tag: el[3] },);
    //   i+=1;
    // })
    // this.tmpData2.forEach(el => {
    //   this.mealList.push({ id: 55+i, name: el[0], mark: parseInt(el[2]), note: el[1], tag: el[3] },);
    //   i+=1;
    // })
    // this.initTable();
    if(!['点心','早餐'].includes(this.nameMap[this.mealType])){
      this.selectDinner(this.dinnerType);
    } else {
      this.initSnack();
    }
  },
  computed: {
    score(){
      let s = 0;
      this.cart.forEach(el => {
        s += el.mark;
      })
      return s
    }
  },
  data() {
    return {
      dialogTableVisible: false,
      dialogTableVisible2: false,
      mealType: '',
      dinnerType: -1,
      isEdit: false,
      inputData: {
        keyword: '',
        mark: '',
        category: '',
      },
      loading: false,
      cart: [],
      newMealForm: {},
      selectValue: [],
      nameMap: {
        snack: '点心',
        breakfast: '早餐',
        lunch: '午餐',
        dinner: '晚餐',
      },
      options: [],
      filters: [
        ['饭食','面食', '麻辣香锅', '火锅'],
        ['素菜','小荤','大荤', '热菜','凉菜'],
      ],
      tableSelect: [],
      mealTableData: [],
      mealList: [
        { id: 0, name: '香肠鸡蛋饼', mark: 8.5, note: '', tag: ['点心'] },
        { id: 1, name: '玉子烧', mark: 8, note: '', tag: ['点心'] },
        { id: 2, name: '思念油条', mark: 8, note: '', tag: ['点心'] },
        { id: 3, name: '龙凤煎饺', mark: 9, note: '', tag: ['点心'] },
        { id: 4, name: '四年春卷', mark: 9, note: '', tag: ['点心'] },
        { id: 5, name: '手捏饭团', mark: 9, note: '海苔芝麻', tag: ['点心'] },
        { id: 6, name: '鸡汁土豆泥', mark: 9.5, note: '黄油/牛奶+鸡汁（可加培根）', tag: ['点心'] },
        { id: 7, name: '炸土豆片', mark: 9.5, note: '先汆水，炸时加黄油、盐、椒盐、黑/白胡椒', tag: ['点心'] },
        { id: 8, name: '牛肉/鸡肉三明治', mark: 9.5, note: '牛奶爱丽丝menu', tag: ['点心', '早餐'] },
        { id: 9, name: '香肠鸡蛋饼', mark: 8.5, note: '', tag:['早餐'] },
        { id: 10, name: '海陆双鲜粥', mark: 9, note: '干贝、里脊、麻油、生抽、金针菇', tag:['早餐', '定食&冷冻速食', '饭食'] },
        { id: 11, name: '白粥', mark: 8.5, note: '配咸蛋/酱瓜/肉松/炒蛋/荷包蛋/煎培根', tag:['早餐'] },
        { id: 12, name: '火腿三明治', mark: 8.5, note: '加沙拉酱', tag:['早餐'] },
        { id: 13, name: '蛋奶星星', mark: 8, note: '加牛奶', tag:['早餐'] },
        { id: 14, name: '味千拉面猪骨汤面', mark: 8, note: '', tag:['早餐'] },
        { id: 15, name: '湾仔码头鲜虾云吞', mark: 8.5, note: '', tag:['早餐'] },
        { id: 16, name: '（番茄）蛋炒饭', mark: 8.5, note: '番茄煸成酱', tag:['定食&冷冻速食', '饭食'] },
        { id: 17, name: '(苔香)培根蛋炒饭', mark: 9, note: '可加海苔芝麻杂菜鸡毛菜', tag:['定食&冷冻速食', '饭食'] },
        { id: 18, name: '鸡肉牛肉炒饭', mark: 8, note: '', tag:['定食&冷冻速食', '饭食'] },
        { id: 19, name: '辣肠菜饭', mark: 7.5, note: '卷心菜比鸡毛菜不易酸（可放3天）', tag:['定食&冷冻速食', '饭食'] },
        { id: 20, name: '翡翠炒饭', mark: 8, note: '虾仁、鸡毛菜、鸡胸肉', tag:['定食&冷冻速食', '饭食'] },
        { id: 21, name: '泡菜培根（辣肠）炒饭', mark: 9, note: '', tag:['定食&冷冻速食', '饭食'] },
        { id: 22, name: '冬笋辣肠菜饭', mark: 8, note: '', tag:['定食&冷冻速食', '饭食'] },
        { id: 23, name: '茄汁蛋包饭', mark: 8.5, note: '鱼汤拿手菜', tag:['定食&冷冻速食', '饭食'] },
        { id: 24, name: '卤肉饭', mark: 9.5, note: '瘦肉（带一点点肥即可）', tag:['定食&冷冻速食', '饭食'] },
        { id: 25, name: '潮香村\"芝士香肠焗饭\"', mark: 9.5, note: '', tag:['定食&冷冻速食', '饭食'] },
        { id: 26, name: '味嘟嘟咖喱饭', mark: 8, note: '两碗饭一包酱', tag:['定食&冷冻速食', '饭食'] },
        { id: 27, name: '广式/韩式煲仔饭', mark: 9, note: '', tag:['定食&冷冻速食', '饭食'] },
        { id: 28, name: '黄灯笼酱蛋炒饭', mark: 9.5, note: '', tag:['定食&冷冻速食', '饭食'] },
        { id: 29, name: '茄汁午餐肉意面', mark: 8.5, note: '加杂菜', tag:['定食&冷冻速食', '面食'] },
        { id: 30, name: '湾仔码头鲜虾云吞', mark: 8.5, note: '', tag:['定食&冷冻速食', '面食'] },
        { id: 31, name: '味千拉面猪骨汤面', mark: 7.5, note: '', tag:['定食&冷冻速食', '面食'] },
        { id: 32, name: '营多拉面', mark: 8, note: '加见过的午餐肉8荷包蛋', tag:['定食&冷冻速食', '面食'] },
        { id: 33, name: '辣炒年糕', mark: 8.5, note: '', tag:['定食&冷冻速食', '面食'] },
        { id: 34, name: '湾仔码头鲜肉小云团', mark: 7.5, note: '', tag:['定食&冷冻速食', '面食'] },
        { id: 35, name: '饺子', mark: 8.5, note: '胡萝卜冬笋猪肉（红油抄手）', tag:['定食&冷冻速食', '面食'] },
        { id: 36, name: '馄饨', mark: 8.5, note: '荠菜猪肉', tag:['定食&冷冻速食', '面食'] },
        { id: 37, name: '麻辣香锅', mark: 9.5, note: '必放：午餐肉、鸡毛菜、土豆、金针菇、2种豆皮、肥牛卷;;选放：鹌鹑蛋、黑木耳、鸡丝、年糕、腐竹', tag:['定食&冷冻速食', '麻辣香锅'] },
        { id: 38, name: '中式酸汤、牛油、海鲜', mark: 9.5, note: '底料、午餐肉、蟹黄包、海胆包、蟹肉棒、土豆粉、油豆皮、牛百叶、肥牛卷、腐竹;;（需采购：青菜、金针菇、鹌鹑蛋、娃娃菜、土豆、豆腐、川粉）', tag:['定食&冷冻速食', '火锅'] },
        { id: 39, name: '韩式部队锅', mark: 9.5, note: '韩式辣酱（加少许生抽）、辛拉面、午餐肉、小香肠、泡菜、年糕、芝士碎、肥牛卷、培根;;(需采购：土豆、金针菇)', tag:['定食&冷冻速食', '火锅'] },
        { id: 40, name: '凉拌豆腐', mark: 8, note: '', tag:['素菜', '凉菜'] },
        { id: 41, name: '腌茄子', mark: 7.5, note: '', tag:['素菜', '凉菜'] },
        { id: 42, name: '番茄沙司土豆', mark: 8.5, note: '', tag:['素菜', '热菜'] },
        { id: 43, name: '白灼生菜', mark: 9.5, note: '用生抽、麻油浇汁', tag:['素菜', '热菜'] },
        { id: 44, name: '上汤娃娃菜', mark: 9.5, note: '', tag:['素菜', '热菜'] },
        { id: 45, name: '刀豆抄土豆', mark: 8.5, note: '', tag:['素菜', '热菜'] },
        { id: 46, name: '蚝油油芦笋', mark: 9, note: '剥皮很麻烦剥完手会痒', tag:['素菜', '热菜'] },
        { id: 47, name: '冬瓜烧毛豆', mark: 8, note: '毛豆先汆熟', tag:['素菜', '热菜'] },
        { id: 48, name: '鸡毛菜炒百叶', mark: 9, note: '', tag:['素菜', '热菜'] },
        { id: 49, name: '爆炒大头菜', mark: 9, note: '生抽加小辣椒', tag:['素菜', '热菜'] },
        { id: 50, name: '茄子炒毛豆', mark: 8, note: '毛豆先汆熟', tag:['素菜', '热菜'] },
        { id: 51, name: '青椒土豆丝', mark: 8.5, note: '', tag:['素菜', '热菜'] },
        { id: 52, name: '鸡汁西兰花', mark: 8, note: '', tag:['素菜', '热菜'] },
        { id: 53, name: '鸡汁土豆泥', mark: 9.5, note: '加黄油/牛奶+鸡汁（可加培根）', tag:['素菜', '热菜'] },
        { id: 54, name: '炸土豆片', mark: 9.5, note: '先汆水、炸时加黄油、盐、椒盐、黑/白胡椒', tag:['素菜', '热菜'] },
        { id: 55, name: '蚝油菜心', mark: 9, note: '生抽加小辣椒', tag:['素菜', '热菜'] },
      ],
      tempData: [
        ['黑木耳/茄子/干贝煎蛋',8.5,'',['小荤']],
        ['杂菜虾仁炒豆腐',8.5,'',['小荤']],
        ['油焖茄子炒肉片',9,'先放茄子，多放油，茄子洗完油后会再吐出',['小荤']],
        ['香肠煸花菜',8,'',['小荤']],
        ['麻婆豆腐',7.5,'加点肉糜，用味好美调料',['小荤']],
        ['（干贝）炖蛋',8,'快出锅时加点酱油',['小荤']],
        ['番茄炒蛋',8.5,'番茄煸成酱',['小荤']],
        ['荷兰豆/四季豆/豇豆炒培根',9.5,'培根可换其他肉',['小荤']],
        ['茭白炒肉丝',8,'',['小荤']],
        ['鸡蛋炒卷心菜',8,'',['小荤']],
        ['黄瓜虾仁',9,'放点鲜辣粉',['小荤']],
        ['黑木耳青椒跑蛋',8.5,'加鸡片',['小荤']],
        ['土豆肉片',9,'',['小荤']],
        ['番茄沙司鹌鹑蛋',9.5,'',['大荤']],
        ['黑椒牛柳',7,'',['大荤']],
        ['潮香村牛排',8,'',['大荤']],
        ['黑椒大虾',8.5,'',['大荤']],
        ['红烧肉片',9,'淀粉、生抽、老抽、五香粉、大料',['大荤']],
        ['鱼香肉丝',7.5,'好人家调料',['大荤']],
        ['三色肉圆',9.5,'酱爆肉圆、辣味鸡肉圆、糖醋肉圆',['大荤']],
        ['盐焗鸡翅',9.5,'鸡翅先汆水，炸时加黄油、盐、椒盐、黑/白胡椒',['大荤']],
        ['咖喱土豆炖牛肉/鸡胸肉',8.5,'牛腩用啤酒煮',['大荤']],
        ['黄灯笼蒸鸡翅',9.5,'先拿生抽腌一下，划几个口，更入味',['大荤']],
        ['红烧鸡翅',9.5,'',['大荤']],
        ['可乐鸡翅',9.5,'牛奶爱丽丝menu',['大荤']],
        ['炸猪排',9.5,'爸爸经典菜',['大荤']],
        ['宫保鸡丁',9.5,'好人家调料',['大荤']],
        ['沙拉酱虾球',8.5,'虾剁泥，耗时长',['大荤']],
        ['油爆虾',9,'爸爸经典菜',['大荤']],
        ['叉烧肉',9.5,'牛奶爱丽丝menu',['大荤']],
        ['叉烧虾',8.5,'',['大荤']],
        
      ],
      tmpData2: [
        ["凉拌黑木耳","生抽 盐 麻油 糖 油泼辣子调汁","7",["标准正餐","素菜"]],
        ["蒜香生菜","","8.5",["标准正餐","素菜"]],
        ["踏焖小土豆","盐蚝油椒盐蒜","8.5",["标准正餐","素菜"]],
        ["蒸茄子","酱料：蒜 蚝油 鲜酱油 酱油 麻油","8.5",["标准正餐","素菜"]],
        ["虾酱空心菜","","8.5",["标准正餐","素菜"]],
        ["金针菇牛肉卷","","8",["标准正餐","小荤"]],
        ["油泼辣子海蜇丝","料酒腌过后麻油醋生抽油泼辣子","7.5",["标准正餐","小荤"]],
        ["周君记土豆肉丝","","9.5",["标准正餐","小荤"]],
        ["塔菜炒肉片","","8",["标准正餐","小荤"]],
        ["糖醋小排","","8.5",["标准正餐","大荤"]],
        ["小鸡锤","牛奶爱丽丝菜谱","8",["标准正餐","大荤"]],
        ["双拼基围虾","先裹蛋液淀粉后炸一遍 一款再裹面包糠一款用咸蛋黄煸成酱裹上","8",["标准正餐","大荤"]],
        ["香煎银鳕鱼","盐 生抽 胡椒粉 料酒先腌制","8",["标准正餐","大荤"]],
        ["红烧肉","","8.5",["标准正餐","大荤"]],
        ["炸肋排","先盐黑胡椒料酒腌制 再裹炸粉","9.5",["标准正餐","大荤"]],
        ["爆炒墨鱼仔","先加入蚝油煸炒蒜 洋葱 再加青椒 盐","8",["标准正餐","大荤"]],
        ["炸鱿鱼圈","用炸鸡翅的炸粉","9",["标准正餐","大荤"]],
        ["萝卜竹荪干贝汤","加肉丸和蛋饺","8.5",["汤"]],
        ["罗宋汤","（土豆 娃娃菜 胡萝卜 番茄 牛肉）汉斯番茄酱"," 9",["汤"]],
        ["裙带菜汤","裙带菜用麻油 酱油 蒜末腌制","9.5",["汤"]],
        ["山药排骨汤","","9 ",["汤"]],
        ["酸汤肥牛","金针菇 肥牛 黑木耳 土豆 莴笋 腐竹","9",["定食&冷冻速食"]],
        ["白菜牛肉锅","（配日本白菜猪肉锅调料） 金针菇 黑木耳 肥牛 里脊 土豆 娃娃菜","8.5",["定食&冷冻速食"]],
        ["酸豆角蟹肉炒饭","全家的酸豆角 蟹肉棒 + 午餐肉腊肠和海苔碎 调料加盐和麻油即可","8.5",["定食&冷冻速食"]],
        ["咸蛋黄虾仁炒饭","","8",["定食&冷冻速食"]],
        ["黑椒蘑菇意面","先放黄油 油 煎两个蛋 先煎培根 鸡肉块 煸一下 呈出剁碎洋葱 蘑菇 盐 煸一下 加牛奶黑胡椒酱 加鸡肉","8",["定食&冷冻速食"]],
        ["咖喱饭","瘦肉 胡萝卜 洋葱 土豆切丁","9",["定食&冷冻速食"]],
        ["鸡公煲","鸡块 洋葱 青椒 火锅料 土豆 豆皮 香菇 （娃娃菜 金针菇）","9.5",["定食&冷冻速食"]],
        ["韩式石锅饭","豆芽 胡萝卜 鸡蛋 牛肉 海苔","9.5",["定食&冷冻速食"]],
        ["番茄焖饭","杂菜 番茄 香肠 土豆 盐 油 香油 蚝油 胡椒粉 最后熟了加一点生抽","8.5",["定食&冷冻速食"]],
        ["西多士-牛奶爱丽丝菜谱","","8",["点心"]],
        ["厚蛋烧","（糖盐牛奶）","9",["点心"]],
        ["烤冷面",""," 9.5",["点心"]],
        ["北极贝寿司","（米饭加油海苔芝麻）","8",["点心"]],
        ["金枪鱼海苔饭团","（东远金枪鱼罐头还可以做三明治）","9.5",["点心"]],
        ["泡菜/培根/金枪鱼寿司","泡菜 黄瓜 萝卜 蛋皮 沙拉 肉松","8.5",["点心"]],
        ["奶油蘑菇汤","50g粉500ml水煮开后放50ml淡奶油","9",["汤"]],
        ["照烧鸡腿饭","鸡腿去骨后用油盐料酒黑胡椒腌制 照烧汁：生抽 老抽 耗油 糖 料酒","9",["饭食","定食&冷冻速食"]],
        ["水煮肉片","猪里脊 金针菇 豆皮 土豆 娃娃菜 豆芽 豆瓣酱 蒜末 花椒","9",["定食&冷冻速食"]],
        ["小酥肉","牛奶爱丽丝菜谱","9.5",["大荤"]],
        ["酸辣土豆丝","牛奶爱丽丝菜谱","8",["素菜"]],
        ["豆腐饼","牛奶爱丽丝菜谱","8",["小荤"]],
        ["嫩豆腐汤","肉糜 大葱虾或蛤蜊 西葫芦 豆腐 嫩豆腐酱","9",["汤"]]
      ],
      tableTagFilters: [],
    };
  },
  methods: {
    initSnack(){
      this.mealTableData = [];
      this.$axios.get('/dinners').then(dinners => {
        const set = new Set(['早餐','点心']);
        console.log(dinners.data)
        this.mealList = dinners.data.data.map(dinner => {
          return {
            name: dinner.name,
            mark: dinner.score,
            note: dinner.note,
            tag: dinner.tags,
            id: dinner.id
          }
        })
        this.mealList.forEach(el => {
          let intersect = new Set([...set].filter(x => el.tag.includes(x)));
          if(intersect.size > 0){
            this.mealTableData.push({
              name: el.name,
              mark: el.mark,
              note: el.note,
              tag: el.tag,
              id: el.id
            })
          }
        })
      }).then(()=>{
        this.updateTags();
      })
    },
    initTable(){
      this.mealTableData = [];
      const set = new Set(['定食&冷冻速食','素菜','小荤','大荤', '汤','标准正餐']);
      return this.$axios.get('/dinners').then(dinners => {
        console.log(dinners.data)
        this.mealList = dinners.data.data.map(dinner => {
          return {
            name: dinner.name,
            mark: dinner.score,
            note: dinner.note,
            tag: dinner.tags,
            id: dinner.id
          }
        })
        // if(this.dinnerType === -1) {}
        if(this.dinnerType === -1){
          this.mealList.forEach(el => {
            this.mealTableData.push({
              name: el.name,
              mark: el.mark,
              note: el.note,
              tag: el.tag,
              id: el.id
            })
          })
        } else {
          this.mealList.forEach(el => {
            let intersect = new Set([...set].filter(x => el.tag.includes(x)));
            if(intersect.size>0){
              this.mealTableData.push({
                name: el.name,
                mark: el.mark,
                note: el.note,
                tag: el.tag,
                id: el.id
              })
            }
          })
        }
      })
      
    },
    filterType(tag){
      if(tag === '素菜'){
        return 'success'
      } else if(['火锅','麻辣香锅'].includes(tag)){
        return 'danger'
      } else {
        return 'primary'
      }
    },
    filterTag(value, row) {
      return row.tag.indexOf(value) >= 0;
    },
    updateSelection(selection){
      this.tableSelect = selection;
    },
    updateTable(){
      this.selectDinner(this.dinnerType).then(() => {
        let temp = [];
        let flag;
        this.mealTableData.forEach(el => {
          flag = 1;
          for(let slc of this.selectValue){
            if(!el.tag.includes(slc)){
              flag = 0;
              break;
            }
          }
          if(flag){
            temp.push(el);
          }
        })
        this.mealTableData = temp;
      });
    },
    showDialog(){
      this.dialogTableVisible = true;
      this.tableSelect = [];
      this.$refs.mealTable.clearSelection();
    },
    showDialog2(){
      this.dialogTableVisible2 = true;
      this.isEdit = false;
      this.initNewMealForm();
    },
    initNewMealForm() {
      this.newMealForm = {
        name: '',
        score: 8.5,
        note: '',
        pwd: '',
        tags: []
      }
      if(this.isEdit) {
        const d = this.tableSelect[0];
        this.newMealForm.id = d.id;
        this.newMealForm.name = d.name;
      } 
    },
    updateTags() {
      return this.$axios.get('/tags').then((tags)=>{
        this.tableTagFilters = tags.data.data.map(tag => {
          return {text: tag.name, value:tag.name}
        })
      })
    },
    selectRow(row, event, column){
      this.$refs.mealTable.toggleRowSelection(row);
    },
    deleteRow(){
      this.tableSelect.forEach(el => {
        this.cart.splice(this.cart.indexOf(el),1);
      })
    },
    // filterTag(value, row) {
    //   return row.tag === value;
    // },
    selectDinner(num){
      this.dinnerType = num;
      this.loading = true;
      return this.initTable().then(()=>{
        if(this.dinnerType !==num){
          this.selectValue = [];
        }
        this.options=[];
        let temp = [];
        if(num === 0){
          this.mealTableData.forEach(el => {
            if(el.tag.includes('定食&冷冻速食')){
              temp.push({
                name: el.name,
                mark: el.mark,
                note: el.note,
                tag: el.tag,
                id: el.id
              })
            }
          })
        } else if(num === 1){
          this.mealTableData.forEach(el => {
            if(!el.tag.includes('定食&冷冻速食')){
              temp.push({
                name: el.name,
                mark: el.mark,
                note: el.note,
                tag: el.tag,
                id: el.id
              })
            }
          })
        } else if(num === -1) {
          temp = this.mealTableData;
        }
        // console.log(temp);
        if(num > 0){
          this.filters[num].forEach(el => {
            this.options.push({label: el, value: el})
          })
        }
        
        // console.log(this.options)
        this.mealTableData = temp;
        this.loading = false;
      }).then(()=>{
        this.updateTags();
      });
      
    },
    choose(){
      this.tableSelect.forEach(el=> {
        this.cart.push(el)
      })
    },
    submitDinner() {
      let newMealForm = this.newMealForm;
      console.log(newMealForm)
      if(newMealForm.pwd !== '' && newMealForm.name !== '' && newMealForm.score > 0 && newMealForm.score <= 10 ) {
        if(this.isEdit) {
          // 修改菜品
          this.$axios.put('/dinner', {data: this.newMealForm}).then(res => {
            this.$message({
              type: 'success',
              message: '修改成功!'
            });
            this.isEdit = false;
            this.dialogTableVisible2 = false;
            this.initNewMealForm();
            if(!['点心','早餐'].includes(this.nameMap[this.mealType])){
              this.selectDinner(this.dinnerType);
            } else {
              this.initSnack();
            } 
          })
        } else {
          // 新增菜品
          this.newMealForm["dinnerName"] = this.newMealForm.name;
          this.$axios.post('/addDinner', this.newMealForm).then((res) => {
            console.log(res)
            this.dialogTableVisible2 = false;
            this.initNewMealForm();
            this.$axios.get('/dinners').then(dinners => {
              this.$axios.get('/tags').then((tags)=>{
                this.tableTagFilters = tags.data.data.map(tag => {
                  return {text: tag.name, value:tag.name}
                })
                this.mealList = dinners.data.data.map(dinner => {
                  return {
                    name: dinner.name,
                    mark: dinner.score,
                    note: dinner.note,
                    tag: dinner.tags,
                    id: dinner.id
                  }
                })
                // this.initTable();
                if(!['点心','早餐'].includes(this.nameMap[this.mealType])){
                  this.selectDinner(this.dinnerType);
                } else {
                  this.initSnack();
                }
              })
            })
          
          })
        }
        
       
      } else {
        alert('请填写必要信息!')
      }
      
    },
    editDinner() {
      if(this.tableSelect.length != 1) {
        this.$message({
          type: 'info',
          message: '请选择一个菜品修改'
        });
        return;
      }
      this.isEdit = true;
      this.dialogTableVisible2 = true;
      const d = this.tableSelect[0]
      this.newMealForm = {
        id: d.id,
        name: d.name,
        note: d.note,
        score: d.mark,
        tags: d.tag,
      };
      

    },
    submitEditDinner() {
      
    },
    deleteDinner() {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const ids = this.tableSelect.map(el => el.id);
        console.log(ids);
        this.$axios.delete('/dinners', {data: {ids: ids}}).then(res => {
          console.log(res.data.msg);
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).then(()=>{
          if(!['点心','早餐'].includes(this.nameMap[this.mealType])){
            this.selectDinner(this.dinnerType);
          } else {
            this.initSnack();
          }
        })
        // console.log(this.tableSelect);
        
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    }
  }
  

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>

.meals{
  display: flex;
  flex-direction: column;
  margin: 5% 3%;
  height: 90%;
  .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h2{
      font-size: 1.5em;
      margin: 5px 20px;
    }
    .el-input{
      margin-left: 1%;
      flex: 1;
    }
  }
  .sub-header{
    margin-top: 3%;
    text-align: left;
    display: flex;
    .el-button{
      margin-right: 2%;
      &.small-btns {
        margin-right: 0;
      }
      &.el-button--default{
        margin-right: 1%;
      }
    }
    .el-badge{
      margin-right: 2%;
    }
    .el-select{
      flex: 1;
      margin-right: 2%;
    }
    .choose{
      align-self: flex-end;
      margin-right: 0;
    }
  }
  .el-table{
    margin-top: 5%;
  }
  .score{
    margin-top: 5%;
    font-size: 28px;
  }
  .footer{
    margin-top: 5%;
    font-size: 28px;
    text-align: left;
  }
}
</style>
