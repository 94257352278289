<template>
  <div class="Marryme">
    <div class="header">
      <img :src="marryme" @click="$router.push('/')">
      <audio class="audio" :src="mp3" controls autoplay="autoplay" hidden="true" loop></audio>
      <div class="row"><span><img :src="ring"></span><span><p>彭程叶，我爱你，一直爱你！</p></span></div>
    </div>
    <div class="body">
      <vue-flux
      :options="fluxOptions"
      :images="fluxImages"
      :transitions="fluxTransitions"
      ref="slider">
          <flux-pagination slot="pagination"></flux-pagination>
    </vue-flux>
    </div>
    <div class="footer">
      <lovebus></lovebus>
    </div>

    <!-- <button @click="$refs.slider.showImage('next')">NEXT</button> -->
    <!-- <flux-parallax src="image" height="300px" offset="80%" type="relative">
      <div>CONTENT</div>
    </flux-parallax> -->
  </div>
</template>

<script>

import { FluxParallax, VueFlux, FluxPagination, Transitions } from 'vue-flux';
import marryme from "@/assets/svg/marryme.svg"
import ring from "@/assets/svg/ring.svg"
import image2 from "@/assets/images/2.jpg"
import image3 from "@/assets/images/3.jpg"
import image4 from "@/assets/images/4.jpg"
import image5 from "@/assets/images/5.jpg"
import image6 from "@/assets/images/6.jpg"
import image06 from "@/assets/images/06.jpg"
import image7 from "@/assets/images/7.jpg"
import image8 from "@/assets/images/8.jpg"
import image9 from "@/assets/images/9.jpg"
import image10 from "@/assets/images/10.jpg"
import image11 from "@/assets/images/11.jpg"
import image12 from "@/assets/images/12.jpg"
import image13 from "@/assets/images/13.jpg"
import image14 from "@/assets/images/14.jpg"
import image15 from "@/assets/images/15.jpg"
import image16 from "@/assets/images/16.jpg"
import image016 from "@/assets/images/016.jpg"
import image17 from "@/assets/images/17.jpg"
import image017 from "@/assets/images/017.jpg"
import image18 from "@/assets/images/18.jpg"
import image19 from "@/assets/images/19.jpg"

import image20 from "@/assets/images/20.jpg"
import image21 from "@/assets/images/21.jpg"
import image22 from "@/assets/images/22.jpg"
import image23 from "@/assets/images/23.jpg"
import image24 from "@/assets/images/24.jpg"
import image25 from "@/assets/images/25.jpg"
import image26 from "@/assets/images/26.jpg"
import image27 from "@/assets/images/27.jpg"
import image28 from "@/assets/images/28.jpg"
import image29 from "@/assets/images/29.jpg"

import image30 from "@/assets/images/30.jpg"
import image31 from "@/assets/images/31.jpg"
import image32 from "@/assets/images/32.jpg"

import image990 from "@/assets/images/990.jpg"
import image991 from "@/assets/images/991.jpg"
import image992 from "@/assets/images/992.jpg"
import image993 from "@/assets/images/993.jpg"

import lovebus from "@/components/lovebus"
import mp3 from "@/assets/media/geiniyuzhou.mp3"

export default {
  name: 'Marryme',
  components: {
    VueFlux,
    FluxPagination,
    FluxParallax,
    marryme,
    lovebus,
  },
  mounted(){

  },
  computed: {
    
  },
  data() {
    return {
      // image: image,
      mp3: mp3,
      marryme: marryme,
      ring: ring,
      fluxOptions: {
         autoplay: true,
         fullscreen: true,
         delay: 3500,
         width: '100%',
         height: 'auto',
      },
      fluxImages: [ 
        image2,
        image3,
        image4, 
        image5, 
        image6, 
        image06, 
        image7, 
        image8, 
        image9,
        image10,
        image11,
        image12,
        image13,
        image14,
        image15,
        image16,
        image016,
        image17,
        image017, 
        image18,
        image19,
        image20,
        image21,
        image22,
        image23,
        image24,
        image25,
        image26,
        image27, 
        image28,
        image29,
        image30,
        image31,
        image32,
        image990,
        image991,
        image992,
        image993
        ],
      fluxTransitions: {
          transitionExplode: Transitions.transitionExplode,
          transitionFade: Transitions.transitionFade,
          transitionBook: Transitions.transitionBook,
          transitionBlocks2: Transitions.transitionBlocks2
      }
    }
  },
  methods: {

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>

.Marryme{
  display: flex;
  flex-direction: column;
  margin: 0 3%;
  height: 100%;
  .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 100px;
    img{
      padding-top: 5px;
      height: 100px;
      width: 100px;
      color: pink;
      fill: pink;
    }
    .row{
      padding-top: 5px;
      display: flex;
      flex-direction: row;
      span{
        padding-left: 25px;
      }
    }
    p{
      color: pink;
      font-size: 32px;
    }
    h2{
      font-size: 1.5em;
      margin: 5px 20px;
    }
    .el-input{
      margin-left: 1%;
      flex: 1;
    }
  }
  .body{
    flex: 1;
    height: 20vw;
    .vue-flux{
      width: 100%;
    }
  }
}
</style>
