<template>
  <div class="cards">
    <div class="header">
      <i class="el-icon-s-ticket"></i>
      <span>土豆仙子的卡券中心</span>

    </div>
    <div class="body">
      <div class="row" v-for="(p, i) in tickets" :key="i">
        <div class="col" v-for="(q, j) in p" :key="j" >
          <el-card>
            <img :src="image33" width="280" class="image">
            <div style="padding: 14px;">
              <span class="sub-title"><b>Massage 使用券</b></span>
              <div class="bottom clearfix">
                <span class="time">有效期：{{ rest }}</span>
                <span><el-button type="text" @click="useCard(2 * i + j)" :disabled="expired || isUsed[2 * i + j]" class="button">使用</el-button></span>
              </div>
            </div>
          </el-card>
          <div class="mask" v-if="expired || isUsed[2 * i + j]">
            <el-button v-if="!expired" type="danger" class="retrieve-btn" @click="retrieveUsed(2 * i + j)" circle>撤销</el-button> 
            <div class="mask-cover"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import image33 from "@/assets/images/33.jpg";

export default {
  name: 'Cards',
  components: {
    image33,
  },
  mounted() {
    if(localStorage.hasOwnProperty("dinnerTimeCards")){
      this.isUsed = JSON.parse(localStorage.dinnerTimeCards);
    } else {
      for(let i = 0; i < 10; i += 1) {
        this.isUsed.push(false);
      }
       
    }
  },
  data() {
    return {
      tickets: [[1, 2], [3, 4], [5, 6], [7, 8], [9, 10]],
      isUsed: [],
      expired: false,
      image33: image33,
    };
  },
  computed: {
    now(){
      return moment().format('llll');
    },
    rest(){
      const expireDate = moment([2020, 8, 7]);
      const now = moment();
      if(now.isBefore(expireDate)) {
        return moment().to(expireDate, true);
      } else {
        this.expired = true;
        return "已过期"
      }
      
    }
  },
  methods: {
    useCard(idx) {
      // console.log(idx)
      this.isUsed[idx] = true;
      this.storeLocal();
      this.$forceUpdate();
    },
    retrieveUsed(idx) {
      this.isUsed[idx] = false;
      this.storeLocal();
      this.$forceUpdate();
    },
    storeLocal(){
      if(localStorage.hasOwnProperty("dinnerTimeCards")){
        localStorage.dinnerTimeCards = JSON.stringify(this.isUsed);
      } else {
        localStorage.setItem("dinnerTimeCards", JSON.stringify(this.isUsed));
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import "~@/styles/colors.less";
.cards{
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin: 0 5%;
  height: 100%;
  .header{
    display: flex;
    flex-direction: row;
    font-size: 40px;
    min-height: 100px;
    align-items: center;
    margin: 25px 0 25px;
    i {
      margin-right: 25px;
      font-size: 45px;
    }
  }
  .body{
    overflow-y: scroll;
    flex: 1;
    display: flex;
    flex-direction: column;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    .row{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 55px;
      min-height: 360px;
      .col {
        // width: 40%;
        padding: 5px;
        
        position: relative;
        .el-card{
          width: 375px;
          height: 360px;
          padding-top: 20px;
          &.expired{

          }
        }
        .sub-title{ 
          font-size: 28px;
          padding-bottom: 10px;
        }
        .time{
          margin-right: 10px;
        }
        .button{
          margin: 0;
          padding: 0;
          line-height: 2.0;
          margin-top: 15px;
        }
        .mask{
          width: 375px;
          height: 385px;
          position: absolute;
          top: 0;      
          border-radius: 4px;
          z-index: 999;
          background-color: transparent;
          // background-color: rgb(82, 82, 82);
          //   opacity: 0.8;
          .retrieve-btn {
            position: relative;
            width: 100px;
            height: 100px;
            margin: 0 auto;
            top: 120px;
          }
          .mask-cover{
            position: absolute;
            z-index: -1;
            top: 0;
            width: 100%;
          height: 100%;
            background-color: rgb(82, 82, 82);
            opacity: 0.8;
          }
        }
      }
    }
  }
}
</style>
